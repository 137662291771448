import { useAppOpenCount, useGivenAppFeedpack } from '@/stores/preferences';
import { AppFeedbackType } from '@/types/ui';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { onMounted, reactive, ref } from 'vue';

type GivenAppFeedback = '1' | '-1' | null;

const IOS_STORE_URL =
  'https://apps.apple.com/ru/app/feelyou-mental-health/id1673720647';
const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=io.feelyou.app&hl=en_US&gl=US';

export function useAppFeedback() {
  const openedAppTriggerCount = 7;
  const appOpenCount = ref(0);
  const feedbackModalType = ref<AppFeedbackType>('initial');
  const givenAppFeedback = ref<GivenAppFeedback>(null);
  const modalsState = reactive({
    appFedback: false,
  });
  const platform = Capacitor.getPlatform();

  const { set: setAppOpenCount, get: getAppOpenCount } = useAppOpenCount();
  const { set: setGivenAppFeedback, get: getGivenAppFeedback } =
    useGivenAppFeedpack();

  onMounted(() => {
    if (Capacitor.isNativePlatform()) {
      init();
    }
  });

  async function init() {
    const gF = (await getGivenAppFeedback()) as GivenAppFeedback;
    givenAppFeedback.value = gF;

    if (givenAppFeedback.value) {
      return;
    }

    const count = await getAppOpenCount();
    appOpenCount.value = Number(count);

    if (appOpenCount.value >= openedAppTriggerCount) {
      showAppFeedbackModal();
    }

    await setAppOpenCount(String(appOpenCount.value + 1));
  }

  function showAppFeedbackModal() {
    modalsState.appFedback = true;
  }
  function hideAppFeedbackModal() {
    appOpenCount.value = 0;
    setAppOpenCount('0');
    modalsState.appFedback = false;
  }

  async function handleLikeApp() {
    feedbackModalType.value = 'positive';

    await setGivenAppFeedback('1');
  }

  async function handleDislikeApp() {
    feedbackModalType.value = 'negative';

    await setGivenAppFeedback('-1');
  }
  function handleContactUs() {
    hideAppFeedbackModal();
    window.open('mailto:support@feelyou.com', '_blank');
  }
  function handleLeaveReaview() {
    hideAppFeedbackModal();

    if (platform === 'ios') {
      Browser.open({
        url: IOS_STORE_URL,
      });
    } else {
      Browser.open({
        url: ANDROID_STORE_URL,
      });
    }
  }

  return {
    appOpenCount,
    modalsState,
    feedbackModalType,

    showAppFeedbackModal,
    hideAppFeedbackModal,
    handleLikeApp,
    handleDislikeApp,
    handleContactUs,
    handleLeaveReaview,
  };
}
