<template>
  <ion-app :class="{ 'ion-app--install-app-banner': state.showAppBanner }">
    <app-banner
      v-if="state.showAppBanner"
      @hide="hideAppBanner"
      @close="state.showAppBanner = false"
    />
    <ion-router-outlet />
    <no-internet-modal
      v-if="route.name !== 'Onboarding'"
      :is-opened="state.modalsVisibility.noInternet"
      @close="handleCloseNoInternet"
    />
    <tutorial-modal
      v-if="tutorialText"
      :is-opened="state.modalsVisibility.tutorial"
      :is-last="tutorialText.isLast"
      :title="tutorialText.title"
      :text="tutorialText.text"
      @close="handleCloseTutorial"
    />
    <request-notifications-modal
      :is-opened="userStore.isShowRequestNotifications"
      @close="userStore.setIsShowRequestNotifications(false)"
    />
    <completed-achievement-modal
      :is-opened="userStore.isShowCompletedAchievement"
      @close="userStore.setIsCompletedAchievement(false)"
    />
    <app-feedback-modal
      v-if="Capacitor.isNativePlatform()"
      :is-opened="feedbackModalsState.appFedback"
      :type="feedbackModalType"
      @close="hideAppFeedbackModal"
      @like="handleLikeApp"
      @dislike="handleDislikeApp"
      @review="handleLeaveReaview"
      @contact="handleContactUs"
    />
    <install-app-modal
      v-if="!Capacitor.isNativePlatform()"
      :is-opened="isInstallAppModalVisible"
      @close="closeInstallAppModal"
    />
    <hello-modal />
    <navigation-tapbar />
  </ion-app>
</template>

<script setup lang="ts">
import { reactive, computed, watch, onMounted } from 'vue';
import api from '@/api';
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';
import { useNotifications } from '@/composables/useNotifications';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Network } from '@capacitor/network';
import {
  useTutorialShowedPrefs,
  useAppBannerHidedPrefs,
} from '@/stores/preferences';
import { usePopup } from '@/composables/usePopup';
import { useAppFeedback } from '@/composables/useAppFeedback';
import {
  setNativeControlsStyle,
  updateProgressAchievement,
  showCompletedAchievement,
  clearNotShowCompletedAchievements,
} from '@/services/methods';
import { UserAchievementI } from '@/types';
import { getDayjsInstance } from './services/date';
import { useAppOnlyFeature } from '@/composables/useAppOnlyFeature';
// import { useAppPayment } from '@/composables/useAppPayment';

import AppBanner from '@/components/AppBanner.vue';
import NoInternetModal from '@/components/modals/NoInternetModal.vue';
import TutorialModal from '@/components/modals/TutorialModal.vue';
import RequestNotificationsModal from '@/components/modals/RequestNotificationsModal.vue';
import NavigationTapbar from '@/components/NavigationTapbar.vue';
import AppFeedbackModal from '@/components/modals/AppFeedbackModal.vue';
import CompletedAchievementModal from '@/components/modals/CompletedAchievementModal.vue';
import InstallAppModal from '@/components/modals/InstallAppModal.vue';
import HelloModal from '@/components/modals/HelloModal.vue';
import { useInternalization } from './composables/useInternalization';

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const { t } = useInternalization();

const { notificationService } = useNotifications();
const { toggleErrorPopup } = usePopup();
const {
  modalsState: feedbackModalsState,
  feedbackModalType,
  hideAppFeedbackModal,
  handleDislikeApp,
  handleLikeApp,
  handleLeaveReaview,
  handleContactUs,
} = useAppFeedback();

const dayjs = getDayjsInstance();

const state = reactive<{
  showAppBanner: boolean;
  modalsVisibility: {
    noInternet: boolean;
    tutorial: boolean;
  };
}>({
  showAppBanner: false,
  modalsVisibility: {
    noInternet: false,
    tutorial: false,
  },
});

const { isInstallAppModalVisible, closeInstallAppModal } = useAppOnlyFeature();

const tutorialText = computed(() => {
  if (route.name === 'Feed') {
    return {
      title: t('tutorialItems.feed.title'),
      text: t('tutorialItems.feed.text'),
    };
  } else if (route.name === 'Discover') {
    return {
      title: t('tutorialItems.discover.title'),
      text: t('tutorialItems.discover.text'),
    };
  } else if (route.name === 'Therapy') {
    return {
      title: t('tutorialItems.therapy.title'),
      text: t('tutorialItems.therapy.text'),
    };
  } else if (route.name === 'Help') {
    return {
      title: t('tutorialItems.help.title'),
      text: t('tutorialItems.help.text'),
    };
  } else if (route.name === 'Stories') {
    return {
      title: t('tutorialItems.stories.title'),
      text: t('tutorialItems.stories.text'),
    };
  } else if (route.name === 'MyProfile') {
    return {
      title: t('tutorialItems.myProfile.title'),
      text: t('tutorialItems.myProfile.text'),
      isLast: true,
    };
  }
  return null;
});

watch(
  () => [userStore.isLoadedUser, userStore.isLogged],
  () => {
    if (userStore.isLogged) {
      updateLastOnline();
      checkHaveCompletedAchievements();
    }
  }
);

onMounted(async () => {
  window.addEventListener('beforeinstallprompt', openAppBanner);
  await SplashScreen.hide();
  if (Capacitor.isNativePlatform()) {
    Keyboard.setAccessoryBarVisible({ isVisible: true });

    setNativeControlsStyle('light');

    useBackButton(1, (processNextHandler) => {
      const mainPages = [
        'discover',
        'feed',
        'therapy',
        'stories',
        'my-profile',
      ];
      if (
        !router.options.history.state.back ||
        (mainPages.find((mainPage) => route.path === `/${mainPage}`) &&
          !mainPages.find(
            (mainPage) => router.options.history.state.back === `/${mainPage}`
          ))
      ) {
        return App.exitApp();
      }
      processNextHandler();
    });
  }
  const isNetworkConnected = await checkNetworkConnected();
  if (isNetworkConnected) {
    openTutorial();
  }
});

api.auth.watchAuthState(async (u) => {
  try {
    if (u) {
      const userData = await api.users.getById(u.uid);
      console.log('user data', userData, u);

      if (userData) {
        if (u.email && userData.email !== u.email) {
          await api.users.update(userData.id, { email: u.email });
          userData.email = u.email;
        }

        userStore.setUser({
          ...userData,
          language: userStore.user.language || 'en',
        });

        notificationService.registerNotifications();

        api.auth.stopWatchAuthState();
      } else {
        toggleErrorPopup("Can't find user");
        await userStore.unsetUser();
        await api.auth.logout();
      }
    } else {
      await userStore.unsetUser();
      await api.auth.logout();
    }
  } catch (e) {
    console.error(e);
    await userStore.unsetUser();
    await api.auth.logout();
  }
  userStore.setIsLoadedUser(true);
});

async function checkNetworkConnected() {
  const status = await Network.getStatus();
  if (!status.connected) {
    state.modalsVisibility.noInternet = true;
  }
  return status.connected;
}

async function openTutorial() {
  const { get } = useTutorialShowedPrefs();
  const isTutorialShowed = await get();
  if (!isTutorialShowed) {
    state.modalsVisibility.tutorial = true;
  }
}

function handleCloseTutorial(isNext?: boolean) {
  if (!isNext) {
    state.modalsVisibility.tutorial = false;
    return;
  }
  if (route.name === 'Feed') {
    router.push({ name: 'Discover' });
  } else if (route.name === 'Discover') {
    router.push({ name: 'Therapy' });
  } else if (route.name === 'Therapy') {
    router.push({ name: 'Help' });
  } else if (route.name === 'Help') {
    router.push({ name: 'Stories' });
  } else if (route.name === 'Stories') {
    router.push({ name: 'MyProfile' });
  } else if (route.name === 'MyProfile') {
    state.modalsVisibility.tutorial = false;
    router.push({ name: 'Feed' });
  }
}

function handleCloseNoInternet() {
  state.modalsVisibility.noInternet = false;
  openTutorial();
}

async function openAppBanner() {
  const { get } = useAppBannerHidedPrefs();
  const isAppBannerHided = await get();
  if (!isAppBannerHided) {
    state.showAppBanner = true;
  }
}

function hideAppBanner() {
  const { set } = useAppBannerHidedPrefs();
  set('yes');
  state.showAppBanner = false;
}

async function updateLastOnline() {
  if (userStore.user.lastOnline) {
    const prevDate = dayjs(userStore.user.lastOnline).format('YYYY-MM-DD');
    const nowDate = dayjs().format('YYYY-MM-DD');
    const diffDays = dayjs(nowDate).diff(dayjs(prevDate), 'days');
    if (diffDays === 1) {
      updateProgressAchievement('motivationMaster');
    } else if (diffDays >= 2) {
      updateProgressAchievement('motivationMaster', 'reset');
    }
  } else {
    updateProgressAchievement('motivationMaster');
  }

  userStore.user.lastOnline = dayjs().format();
  try {
    await api.users.update(userStore.user.id, {
      lastOnline: userStore.user.lastOnline,
    });
  } catch (e) {
    console.error('updateLastOnline', e);
    await userStore.unsetUser();
    await api.auth.logout();
  }
}

async function checkHaveCompletedAchievements() {
  if (!userStore.user.achievements) {
    return;
  }
  let userDataCompletedAchievement: UserAchievementI | null = null;
  for (const userAchievement of userStore.user.achievements) {
    if (userAchievement.isNotShowCompleted) {
      userDataCompletedAchievement = userAchievement;
    }
  }
  if (userDataCompletedAchievement) {
    const achievement = await api.achievements.getById(
      userDataCompletedAchievement.id
    );
    showCompletedAchievement(achievement, userDataCompletedAchievement);
    clearNotShowCompletedAchievements();
  }
}
</script>
